/* Header Section */
.intro {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.intro video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translateX(-50%) translateY(-50%);
    object-fit: cover;
    z-index: -1;
}

.overlay {
    position: relative;
    z-index: 1;
}
.intro h1 {
    font-family: 'Raleway', sans-serif;
    color: #fff;
    font-size: 42px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 10px;
}
.intro h1 span {
    font-weight: 800;
    color: #5ca9fb;
}
.intro p {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 60px;
}
.intro-text {
    padding-top: 350px;
    padding-bottom: 200px;
    text-align: center;
}
