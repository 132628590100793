/* Mobile styles */
.product-container {
    padding: 0 15px;
}

.product-image {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
}

.product-card {
    padding: 20px;
    margin: 0 0 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-title {
    font-family: 'Arial', sans-serif;
    line-height: 1.5;
    color: #333;
}

.product-list {
    list-style-type: disc;
    margin-left: 20px;
    column-count: 1;
    column-gap: 20px;
    text-align: left;
}

/* Desktop styles */
@media (min-width: 768px) {
    .product-container {
        padding: 0;
    }

    .product-list {
        column-count: 2;
    }
}
