/* General Navbar Styles */
#menu {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 15px;
    transition: all 0.8s;
    z-index: 1000; /* Ensure it's on top */
}

#menu .container {
    display: flex;
    align-items: center;
}

#menu .McButton {
    z-index: 10;
}
/* Styles for Nav Links */
.nav-link {
    position: relative;
    color: #555;
    font-size: 15px;
    text-transform: uppercase;
    margin: 9px 20px 0;
}

/* Hover underline effect */
.nav-link:after {
    display: block;
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    height: 2px;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    content: '';
    transition: width 0.2s;
}

.nav-link:hover:after {
    width: 100%;
}

/* Hamburger Menu */
.McButton {
    position: absolute;
    top: 50%;
    right: 10%; /* Align to the right side */
    transform: translateY(-50%); /* Center vertically */
    width: 44px;
    height: 30px; /* Adjust as needed */
    cursor: pointer;
    display: none; /* Hide by default */
}

.McButton b {
    position: absolute;
    left: 0;
    width: 40px;
    height: 3px;
    background-color: black;
    transition: all 0.5s ease;
}

.McButton b:nth-child(1) {
    top: 0;
}
.McButton b:nth-child(2) {
    top: 50%;
}
.McButton b:nth-child(3) {
    top: 100%;
}

.McButton.active b:nth-child(1) {
    top: 50%;
}

.McButton.active b:nth-child(2) {
    opacity: 0; /* Hide the middle bar */
}

.McButton.active b:nth-child(3) {
    top: 50%;
    transform: rotate(90deg);
}

.McButton.active {
    transform: rotate(135deg);
}

@media screen and (max-width: 992px) {
    /* Adjust the breakpoint as needed */
    .McButton {
        display: block; /* Show on smaller screens */
    }
}
