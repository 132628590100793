body,
html {
    font-family: 'Open Sans', sans-serif;
    text-rendering: optimizeLegibility !important;
    color: #777;
    font-weight: 400;
    width: 100% !important;
    height: 100% !important;
}
h2,
h3,
h4 {
    font-family: 'Raleway', sans-serif;
}
h2 {
    text-transform: uppercase;
    margin: 0 0 20px;
    font-weight: 800;
    font-size: 36px;
    color: #333;
}
h3 {
    font-size: 20px;
    font-weight: 600;
    color: #333;
}
h4 {
    font-size: 18px;
    color: #333;
    font-weight: 600;
}
h5 {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 20px;
}
p {
    font-size: 15px;
}
p.intro {
    margin: 12px 0 0;
    line-height: 24px;
}
a {
    color: #608dfd;
    font-weight: 400;
}
a:hover,
a:focus {
    text-decoration: none;
    color: #608dfd;
}
ul,
ol {
    list-style: none;
}
ul,
ol {
    padding: 0;
}
hr {
    height: 2px;
    width: 70px;
    text-align: center;
    position: relative;
    background: #1e7a46;
    margin-bottom: 20px;
    border: 0;
}

.section-title {
    margin-bottom: 70px;
}
.section-title h2 {
    position: relative;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 15px;
}
.section-title h2::after {
    position: absolute;
    content: '';
    background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
    height: 4px;
    width: 60px;
    bottom: 0;
    margin-left: -30px;
    left: 50%;
}
.section-title p {
    font-size: 18px;
}

.main-content {
    min-height: calc(
        100vh - 100px
    ); /* Adjust 100px to match the height of your footer */
    position: relative;
}

.breadcrumbs {
    display: flex;
    justify-content: center;
}

.breadcrumb-item a {
    text-decoration: none;
    color: black;
}

/* Features Section */
/* #features {
    background: #f6f6f6;
}
#features i.fa {
    font-size: 38px;
    margin-bottom: 20px;
    transition: all 0.5s;
    color: #fff;
    width: 100px;
    height: 100px;
    padding: 30px 0;
    border-radius: 50%;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
} */
