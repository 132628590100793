/* Team Section */
#team {
    padding: 100px 0;
}
.team-img {
    height: 300px;
    object-fit: cover; /* this ensures the aspect ratio of the image is preserved */
}

#team h4 {
    margin: 5px 0;
}
#team .thumbnail {
    background: transparent;
    border: 0;
}
#team .thumbnail .caption {
    padding: 10px 0 0;
    color: #888;
}
