.footer {
    background-color: #f8f9fa;
    padding: 20px;
    width: 100%;
    height: 80px;
}

/* .footer h5 {
    margin-bottom: 15px;
}

.footer ul {
    list-style: none;
    padding: 0;
}

.footer ul li {
    margin-bottom: 5px;
}

.footer a {
    color: #333;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
} */
