/* Clients Section */
#testimonials {
    padding: 100px 0;
    background: #f6f6f6;
}
#testimonials i {
    color: #e6e6e6;
    font-size: 32px;
    margin-bottom: 20px;
}
.testimonial {
    position: relative;
    padding: 20px;
}
.testimonial-image {
    float: left;
    margin-right: 15px;
}
.testimonial-image,
.testimonial-image img {
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
}
.testimonial-content {
    position: relative;
    overflow: hidden;
}
.testimonial-content p {
    margin-bottom: 0;
    font-size: 14px;
    font-style: italic;
}
.testimonial-meta {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 600;
    color: #666;
}
