/* Contact Section */
#contact {
    padding: 100px 0 60px;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
}
#contact .section-title {
    margin-bottom: 40px;
}
#contact .section-title p {
    font-size: 16px;
}
#contact h2 {
    color: #fff;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 15px;
}
#contact .section-title h2::after {
    position: absolute;
    content: '';
    background: rgba(255, 255, 255, 0.3);
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 50%;
}
#contact h3 {
    margin-top: 30px;
    margin-bottom: 25px;
    padding-bottom: 20px;
    font-weight: 400;
}
/* Input Field Styling */
#contact Form.Control {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
}

#contact Form.Group {
    margin-bottom: 20px;
}

/* Button Styling */
#contact Button {
    background-color: #f6f6f6;
    border: none;
    padding: 12px 25px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: black;
    margin-bottom: 20px;
}

#contact Button:hover {
    background-color: #5ca9fb;
}

/* Additional Typography Styling */
#contact .section-title p {
    font-size: 16px;
    line-height: 1.5;
}

#contact .section-title h2 {
    font-weight: 600;
}
